<app-app-menu></app-app-menu>

<section>
  <!-- Any extra menu items, are placed at the far left -->
  <ng-content></ng-content>

  <!-- Application functions/widgets -->
  @if (config != null) {
    @for (group of config(); track group.name) {
      <div>
        <header>{{ group.name | translate }}</header>
        <ul>
          @for (widget of group.items; track widget) {
            <li>
              <app-widget-link [widgetName]="widget"></app-widget-link>
            </li>
          }
        </ul>
      </div>
    }
  }
  @if (!config.length) {
    <div></div>
  }

  <!-- Main menu -->
  <div class="main">
    <header>{{ 'Admin' | translate }}</header>
    <ul>
      <li *libCustomerHasScopes="['developer']">
        <a [routerLink]="['/developer']" [queryParams]="{ stateData: returnState }">
          <mat-icon>settings</mat-icon>
          <span>{{ 'Developer' | translate }}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/help']" [queryParams]="{ stateData: returnState }">
          <mat-icon>help_outline</mat-icon>
          <span>{{ 'Help' | translate }}</span>
        </a>
      </li>
      <li *appHasFeature="'user-management'">
        <a [routerLink]="['/users']" [queryParams]="{ stateData: returnState }" (click)="preventDefault($event)">
          <mat-icon>supervised_user_circle</mat-icon>
          <span>{{ 'User management' | translate }}</span>
        </a>
      </li>
      <li *appHasFeature="'settings'">
        <a [routerLink]="['/settings']" [queryParams]="{ stateData: returnState }" (click)="preventDefault($event)">
          <mat-icon>settings</mat-icon>
          <span>{{ 'Settings' | translate }}</span>
        </a>
      </li>
      <li *appHasFeature="'configuration'">
        <a
          [routerLink]="['/config']"
          [queryParamsHandling]="'merge'"
          [queryParams]="{ stateData: returnState }"
          (click)="preventDefault($event)"
        >
          <mat-icon>admin_panel_settings</mat-icon>
          <span>{{ 'Configuration' | translate }}</span>
        </a>
      </li>
      <li *appHasFeature="'configuration'">
        <a
          [routerLink]="['/co2-config']"
          [queryParamsHandling]="'merge'"
          [queryParams]="{ stateData: returnState }"
          (click)="preventDefault($event)"
        >
          <mat-icon>admin_panel_settings</mat-icon>
          <span>{{ 'Energy/Waste Configuration' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/select-customer']"
          [queryParams]="{ stateData: returnState }"
          (click)="preventDefault($event)"
        >
          <mat-icon>swap_horiz</mat-icon>
          <span>{{ 'Change affiliate' | translate }}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/logout']" (click)="preventDefault($event)">
          <app-icon-exit></app-icon-exit>
          <span>{{ 'Logout' | translate }}</span>
        </a>
      </li>
    </ul>

    <header>{{ 'Customization' | translate }}</header>
    <lib-language-selector (click)="preventClose($event)" (langChanged)="setLanguage($event)"></lib-language-selector>
    <lib-theme-switch></lib-theme-switch>
  </div>
</section>
